import { gql, useLazyQuery } from '@apollo/client'
import { Box } from 'caixa'
import { useEffect, useRef, useState } from 'react'
import { toast } from 'react-hot-toast'
import {
  ActionFunction,
  json,
  LoaderFunction,
  redirect,
  RouteMatch,
  useActionData,
  useLoaderData,
} from 'react-router-dom'

import { CustomerFile } from '../../../types'
import { baseUrl, graphql } from '../api'
import Breadcrumb from '../components/Breadcrumb'
import ErrorBoundary from '../components/ErrorBoundary'
import FileBrowser from '../components/FileBrowser'
import Separator from '../components/Separator'
import { dedupe } from '../utils/dedupe'

export const path = '/cliente/:customerId/arquivos/*'

export const id = 'files'

export const action: ActionFunction = async ({ params, request }) => {
  const formData = await request.formData()
  const name = formData.get('name')?.toString()
  const action = formData.get('action')?.toString()
  const key = formData.get('key')?.toString()
  const keys = formData.get('keys')?.toString()
  const prefix = formData.get('prefix')?.toString()

  const errors: Partial<Record<'name', string>> = {}

  if (action === 'folder') {
    if (typeof name !== 'string' || !name)
      errors.name = 'Nome da pasta é obrigatório'

    if (Object.keys(errors).length) return json({ errors })

    const key = `${params.customerId}${prefix ? `/${prefix}` : ''}/${name}`

    const response = await graphql.mutate({
      mutation: gql`
        mutation ($key: String!) {
          createFolder(key: $key) {
            key
            name
          }
        }
      `,
      variables: {
        key,
      },
      errorPolicy: 'all',
    })

    if (!response.data?.createFolder?.key) {
      return json({ errors: response.errors })
    }

    return redirect(
      `/cliente/${params.customerId}/arquivos${
        prefix ? `/${prefix}` : ''
      }/${name}`,
    )
  } else if (action === 'files') {
    const files = formData.getAll('files')

    await Promise.all(
      files.map(async (file) => {
        if (file instanceof File && file.name) {
          const {
            data: { getSignedUrl },
          } = await graphql.mutate({
            mutation: gql`
              mutation ($key: String!) {
                getSignedUrl(key: $key) {
                  key
                  signedUrl
                }
              }
            `,
            variables: {
              key: `${params.customerId}${prefix ? `/${prefix}` : ''}/${
                file.name
              }`,
            },
          })

          await fetch(getSignedUrl.signedUrl, {
            method: 'put',
            body: file,
          })
        }
      }),
    )

    return json({})
  } else if (action === 'delete' && keys) {
    const keysArr = keys.split(',')
    await Promise.all(
      keysArr.map((key) =>
        graphql.mutate({
          mutation: gql`
            mutation ($key: String!) {
              deleteFile(key: $key)
            }
          `,
          variables: {
            key,
          },
          errorPolicy: 'all',
        }),
      ),
    )

    return json({
      clearList: true,
    })
  } else if (action === 'delete') {
    await graphql.mutate({
      mutation: gql`
        mutation ($key: String!) {
          deleteFile(key: $key)
        }
      `,
      variables: {
        key,
      },
      errorPolicy: 'all',
    })

    return json({})
  } else if (action === 'zip' && keys) {
    const response = await graphql.mutate({
      mutation: gql`
        mutation ($customerId: String!, $keys: [String!]!) {
          createFileBatch(customerId: $customerId, keys: $keys) {
            id
          }
        }
      `,
      variables: {
        customerId: params.customerId,
        keys: keys.split(','),
      },
      errorPolicy: 'all',
    })

    return json(response.data)
  } else {
    return json({})
  }
}

export const handle = {
  breadcrumb: ({ params }: RouteMatch) => [
    <Breadcrumb key={id} to={`/cliente/${params.customerId}/arquivos`}>
      Arquivos
    </Breadcrumb>,
    <Separator key='separator' />,
    ...(params['*']
      ? params['*'].split('/').flatMap((prefix, index, array) => [
          <Breadcrumb
            key={`${id}_${index}`}
            to={`/cliente/${params.customerId}/arquivos/${array
              .slice(0, array.indexOf(prefix) + 1)
              .join('/')}`}
          >
            {prefix}
          </Breadcrumb>,
          index < array.length - 1 ? (
            <Separator key={`${index}_separator`} />
          ) : null,
        ])
      : []),
  ],
  title: () => 'Arquivos',
}

export const loader: LoaderFunction = async ({ params }) => {
  const prefix = `${params.customerId}/${params['*'] ? `${params['*']}/` : ''}`

  const response = await graphql.query({
    query: gql`
      query ($prefix: String!) {
        files(prefix: $prefix) {
          key
          name
          type
          deletedAt
        }
      }
    `,
    variables: {
      prefix,
    },
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  })

  return json(response.data.files)
}

function Component() {
  const loaderData = useLoaderData() as CustomerFile[]
  const actionData = useActionData() as { createFileBatch: { id: string } }
  const [fileBatchId, setFileBatchId] = useState<string | null>(null)
  const [, { data, startPolling, stopPolling }] = useLazyQuery(
    gql`
      query ($id: String!) {
        fileBatch(id: $id) {
          id
          status
          name
        }
      }
    `,
    { variables: { id: fileBatchId } },
  )
  const items = loaderData
    ? dedupe<{
        key: string
        name: string
        type: 'FILE' | 'FOLDER'
      }>(
        loaderData.reduce(
          (acc, item) => {
            if (!item.name || item.deletedAt) return acc
            return [
              ...acc,
              {
                name: item.name,
                type: item.type,
                key: item.key,
              },
            ]
          },
          [] as {
            key: string
            name: string
            type: 'FILE' | 'FOLDER'
          }[],
        ),
        'name',
      )
    : []

  const toastId = useRef<string>('')
  useEffect(() => {
    if (actionData?.createFileBatch?.id) {
      setFileBatchId(actionData?.createFileBatch?.id)
      startPolling(2500)
      toastId.current = toast.loading('Preparando os arquivos')
    }
  }, [actionData?.createFileBatch?.id, startPolling])

  useEffect(() => {
    if (data?.fileBatch?.status !== 'PENDING') {
      stopPolling()
      toast.dismiss(toastId.current)
      if (data?.fileBatch?.status === 'ERROR') {
        toast.error(
          'Ocorreu um erro ao preparar seus arquivos. Tente novamente.',
        )
      }
      if (data?.fileBatch?.status === 'READY') {
        toast.success('Seus arquivos estão prontos para baixar', {
          duration: 5000,
        })
        const link = document.createElement('a')
        link.download = data.fileBatch.name
        link.href = `${baseUrl}/file/zip/${data.fileBatch.name}`
        link.target = '_blank'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
    }
  }, [data?.fileBatch?.status, stopPolling])

  return (
    <Box
      alignItems='center'
      display='flex'
      justifyContent='center'
      width='100%'
    >
      <FileBrowser items={items} isAdmin />
    </Box>
  )
}

export const errorElement = <ErrorBoundary />

export const element = <Component />
